<app-sidebar></app-sidebar>

<!-- Page title-->
<div class="border-bottom pt-5 mt-2 mb-5">
    <h1 class="mt-2 mt-md-4 mb-3 pt-5">Clientes</h1>
    <div class="d-flex flex-wrap flex-md-nowrap justify-content-between">
      <p class="text-muted">Este módulo lista todos los clientes de la tienda.</p>
      
    </div>
  </div>

    <form #registroForm="ngForm" (ngSubmit)="registro(registroForm)">
        <div class="card box-shadow-sm">
                <div class="card-header">
                    <h5 style="margin-bottom: 0px;">Registro de cliente</h5>
                </div>
                <div class="card-body">
                    
                    <div class="row">
                        <div class="col-md-4 form-group">
                            <label for="validationCustom01" class="form-label">Nombres</label>
                            <input class="form-control" type="text" placeholder="Nombres completos" required name="nombres" [(ngModel)]="cliente.nombres">
                        </div>
                        <div class="col-md-4 form-group">
                            <label for="validationCustom01" class="form-label">Apellidos</label>
                            <input class="form-control" type="text" placeholder="Apellidos completos" required name="apellidos" [(ngModel)]="cliente.apellidos">
                        </div>
                        <div class="col-md-4 form-group">
                            <label for="validationCustom01" class="form-label">Correo electrónico</label>
                            <input class="form-control" type="email" placeholder="Correo electrónico" required name="email" [(ngModel)]="cliente.email">
                        </div>

                        <div class="col-md-4 form-group">
                            <label for="validationCustom01" class="form-label">Telefono</label>
                            <input class="form-control" type="text" placeholder="Telefono" required name="telefono" [(ngModel)]="cliente.telefono">
                        </div>
                        <div class="col-md-4 form-group">
                            <label for="validationCustom01" class="form-label">Fecha nacimiento</label>
                            <input class="form-control" type="date" required name="f_nacimiento" [(ngModel)]="cliente.f_nacimiento">
                        </div>
                        <div class="col-md-4 form-group">
                            <label for="validationCustom01" class="form-label">DNI</label>
                            <input class="form-control" type="number" placeholder="DNI" required name="dni" [(ngModel)]="cliente.dni">
                        </div>

                        <div class="col-md-4">
                            <label for="validationCustom01" class="form-label">Genero</label>
                            <select class="form-control custom-select" name="genero" required [(ngModel)]="cliente.genero">
                                <option value="" disabled selected>Seleccionar</option>
                                <option value="Masculino">Masculino</option>
                                <option value="Femenino">Femenino</option>
                            </select>
                        </div>
                    </div>
                    
                </div>
                <div class="card-footer">
                    <button *ngIf="!load_btn" class="btn btn-primary mr-3" type="submit">Crear cliente</button>
                    <button *ngIf="load_btn" type="button" class="btn btn-secondary" disabled>
                        <span class="spinner-grow spinner-grow-sm mr-2" role="status" aria-hidden="true"></span>
                        Creando...
                    </button>
                    <a [routerLink]="['/panel/clientes']" class="btn btn-warning">Regresar</a>
                </div>
        </div>  
    </form>