<app-sidebar></app-sidebar>

<!-- Page title-->
<div class="border-bottom pt-5 mt-2 mb-5">
    <h1 class="mt-2 mt-md-4 mb-3 pt-5">Dashboard</h1>
    <div class="d-flex flex-wrap flex-md-nowrap justify-content-between">
    </div>
</div>

<div class="row mb-5">
    <div class="col-12 col-lg-6 col-xl">

      <!-- Value  -->
      <div class="card">
        <div class="card-body">
          <div class="row align-items-center gx-0">
            <div class="col">

              <!-- Title -->
              <h6 class="text-uppercase text-muted mb-2">
                Ganancia total
              </h6>

              <!-- Heading -->
              <span class="h2 mb-0">
                ${{total_ganancia}}
              </span>

            </div>
            <div class="col-auto">

              <!-- Icon -->
              <span class="h2 fe fe-dollar-sign text-muted mb-0"></span>

            </div>
          </div> <!-- / .row -->
        </div>
      </div>

    </div>
    <div class="col-12 col-lg-6 col-xl">

      <!-- Hours -->
      <div class="card">
        <div class="card-body">
          <div class="row align-items-center gx-0">
            <div class="col">

              <!-- Title -->
              <h6 class="text-uppercase text-muted mb-2">
                Ganancia del mes
              </h6>

              <!-- Heading -->
              <span class="h2 mb-0">
                ${{total_mes}}
              </span>

            </div>
            <div class="col-auto">

              <!-- Icon -->
              <span class="h2 fe fe-briefcase text-muted mb-0"></span>

            </div>
          </div> <!-- / .row -->
        </div>
      </div>

    </div>
    <div class="col-12 col-lg-6 col-xl">

      <!-- Exit -->
      <div class="card">
        <div class="card-body">
          <div class="row align-items-center gx-0">
            <div class="col">

              <!-- Title -->
              <h6 class="text-uppercase text-muted mb-2">
               N° ventas
              </h6>

              <!-- Heading -->
              <span class="h2 mb-0">
                {{count_ventas}}
              </span>

            </div>
            <div class="col-auto">

              <!-- Chart -->
              <div class="chart chart-sparkline">
                <canvas class="chart-canvas" id="sparklineChart" width="75" height="35" style="display: block; box-sizing: border-box; height: 35px; width: 75px;"></canvas>
              </div>

            </div>
          </div> <!-- / .row -->
        </div>
      </div>

    </div>
    <div class="col-12 col-lg-6 col-xl">

      <!-- Time -->
      <div class="card">
        <div class="card-body">
          <div class="row align-items-center gx-0">
            <div class="col">

              <!-- Title -->
              <h6 class="text-uppercase text-muted mb-2">
                Mes anterior
              </h6>

              <!-- Heading -->
              <span class="h2 mb-0">
               ${{total_mes_anterior}}
              </span>

            </div>
            <div class="col-auto">

              <!-- Icon -->
              <span class="h2 fe fe-clock text-muted mb-0"></span>

            </div>
          </div> <!-- / .row -->
        </div>
      </div>
    </div>
  </div>


<div class="card box-shadow-sm">
    <div class="card-header">
        <h5 style="margin-bottom: 0px;">Ventas mensuales</h5>
    </div>

    <div class="card-body">
        <div class="row">
            <div class="col-12">
                <canvas id="myChart"></canvas>

            </div>
        </div>
    </div>


</div>  