<app-sidebar></app-sidebar>

<!-- Page title-->
<div class="border-bottom pt-5 mt-2 mb-5">
    <h1 class="mt-2 mt-md-4 mb-3 pt-5">Clientes</h1>
    <div class="d-flex flex-wrap flex-md-nowrap justify-content-between">
      <p class="text-muted">Este módulo lista todos los clientes de la tienda.</p>
      <p class="font-size-sm font-weight-medium pl-md-4">
        <a class="text-nowrap" [routerLink]="['/panel/clientes/registro']">Registrar nuevo<i class="cxi-angle-right font-size-base align-middle ml-1"></i></a></p>
    </div>
</div>

<div class="card box-shadow-sm">
    <div class="card-header">
        <h5 style="margin-bottom: 0px;">Filtro de clientes</h5>
    </div>
    <div class="card-header">
        <form class="form-inline pt-2">
            <label class="sr-only" for="inline-form-input-name">Apellidos</label>
              <input class="form-control mb-3 mr-sm-4" (keyup)="filtro('apellidos')" name="filtro_apellidos" [(ngModel)]="filtro_apellidos" type="text" id="inline-form-input-name" placeholder="Apellidos">
            <label class="sr-only" for="inline-form-input-username">Correo</label>
            <div class="input-group mb-3 mr-sm-4">
            <div class="input-group-prepend">
                <div class="input-group-text"></div>
            </div>
              <input class="form-control" type="text" (keyup)="filtro('apellidos')" name="filtro_correo" [(ngModel)]="filtro_correo"  placeholder="Correo">
            </div>
        

        </form>
    </div>
    <div class="card-body">
        <div class="table-responsive">
            <table class="table table-bordered">
              <thead class="thead-dark">
                <tr>
                  <th>#</th>
                  <th>Nombres</th>
                  <th>Apellidos</th>
                  <th>Correo</th>
                  <th>Opciones</th>
                </tr>
              </thead>
              <tbody *ngIf="load_data">
                  <tr>
                    <td class="text-center" colspan="5">
                      <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                    </td>
                  </tr>
              </tbody>

              <ng-container  *ngIf="!load_data">
                <tbody *ngFor="let item of clientes| slice: (page-1)*pageSize : (page-1) * pageSize + pageSize; let indice = index">
                  <tr>
                    <td>{{indice+1}}</td>
                    <td>{{item.nombres}}</td>
                    <td>{{item.apellidos}}</td>
                    <td>{{item.email}}</td>
                    <td>
                      <div class="btn-group dropdown d-inline-block mb-3 mr-2">
                        <button class="btn btn-outline-secondary btn-sm dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">OPCIONES</button>
                        <div class="dropdown-menu">
                          <a class="dropdown-item" [routerLink]="['/panel/clientes',item._id]" >Editar cliente</a>
                          <a class="dropdown-item" style="cursor: pointer;" data-toggle="modal" [attr.data-target]="'#delete-'+item._id">Eliminar cliente</a>
                        </div>
                      </div>
  
                      <div class="modal fade" id="delete-{{item._id}}" tabindex="-1" style="display: none;" aria-hidden="true">
                        <div class="modal-dialog modal-dialog-centered" role="document">
                          <div class="modal-content">
                            <div class="modal-header">
                              <h4 class="modal-title">Eliminar, {{item.nombres}} {{item.apellidos}}</h4>
                              <button class="close" type="button" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
                            </div>
                            <div class="modal-body">
                              <p>Desea eliminar este cliente?</p>
                            </div>
                            <div class="modal-footer">
                              <button class="btn btn-secondary btn-sm" type="button" data-dismiss="modal">Cancelar</button>
                              <button class="btn btn-primary btn-shadow btn-sm" (click)="eliminar(item._id)" type="button">Si, eliminar</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </ng-container>
              
              
            </table>
        </div>
    </div>
    <div class="card-footer">
        <ngb-pagination
          [(page)]="page"
          [pageSize]="pageSize"
          [collectionSize]="clientes.length"
        ></ngb-pagination>
    </div>
</div>  