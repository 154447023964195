<app-sidebar></app-sidebar>

<!-- Page title-->
<div class="border-bottom pt-5 mt-2 mb-5">
    <h1 class="mt-2 mt-md-4 mb-3 pt-5">Cupones</h1>
    <div class="d-flex flex-wrap flex-md-nowrap justify-content-between">
      <p class="text-muted">Este módulo lista todos los cupones de la tienda.</p>
      <p class="font-size-sm font-weight-medium pl-md-4">
        <a class="text-nowrap" [routerLink]="['/panel/cupones/registro']">Registrar nuevo<i class="cxi-angle-right font-size-base align-middle ml-1"></i></a></p>
    </div>
</div>

<div class="card box-shadow-sm">
    <div class="card-header">
        <h5 style="margin-bottom: 0px;">Filtro de cupones</h5>
    </div>
    <div class="card-header">
        <div class="row">
            <div class="col-6">
                <div class="input-group">
                    <input class="form-control" type="text" placeholder="Buscar por código" name="filtro" [(ngModel)]="filtro">
                    <div class="input-group-append">
                      <button class="btn btn-primary" (click)="filtrar()" type="button">Filtrar</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="card-body">
        <div class="table-responsive">
            <table class="table table-bordered">
              <thead class="thead-dark">
                <tr>
                  <th>Código</th>
                  <th>Tipo</th>
                  <th>Valor</th>
                  <th>Limite</th>
                  <th>Opciones</th>
                </tr>
              </thead>
              <tbody *ngIf="load_data">
                  <tr>
                    <td class="text-center" colspan="5">
                      <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                    </td>
                  </tr>
              </tbody>

              <ng-container  *ngIf="!load_data">
                  <tbody *ngFor="let item of cupones| slice: (page-1)*pageSize : (page-1) * pageSize + pageSize;">
                    <tr>
                      <td>{{item.codigo}}</td>
                      <td>{{item.tipo}}</td>
                      <td>{{item.valor}}</td>
                      <td>{{item.limite}} restantes</td>
                      <td>
                        <div class="btn-group dropdown">
                          <button type="button" class="btn btn-outline-secondary btn-sm dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            OPCIONES
                          </button>
                          <div class="dropdown-menu">
                            <a class="dropdown-item" style="cursor: pointer;" data-toggle="modal" [attr.data-target]="'#delete-'+item._id">Eliminar</a>
                            <a [routerLink]="['/panel/cupones',item._id]" class="dropdown-item">Editar</a>

                          </div>
                        </div>

                        <div class="modal fade" id="delete-{{item._id}}" tabindex="-1" style="display: none;" aria-hidden="true">
                          <div class="modal-dialog modal-dialog-centered" role="document">
                            <div class="modal-content">
                              <div class="modal-header">
                                <h4 class="modal-title">Eliminar el cupón {{item.codigo}}</h4>
                                <button class="close" type="button" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
                              </div>
                              <div class="modal-body">
                                <p>Desea eliminar este cupon?</p>
                              </div>
                              <div class="modal-footer">
                                <button class="btn btn-secondary btn-sm" type="button" data-dismiss="modal">Cancelar</button>
                                <button class="btn btn-primary btn-shadow btn-sm" (click)="eliminar(item._id)" type="button">Si, eliminar</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
              </ng-container>
              
              
            </table>
        </div>
    </div>
    <div class="card-footer">
        <ngb-pagination
          [(page)]="page"
          [pageSize]="pageSize"
          [collectionSize]="cupones.length"
        ></ngb-pagination>
    </div>
</div>  