<app-sidebar></app-sidebar>

<!-- Page title-->
<div class="border-bottom pt-5 mt-2 mb-5">
    <h1 class="mt-2 mt-md-4 mb-3 pt-5">Productos</h1>
    <div class="d-flex flex-wrap flex-md-nowrap justify-content-between">
      <p class="text-muted">Este módulo lista todos los productos de la tienda.</p>
      <p class="font-size-sm font-weight-medium pl-md-4">
        <a class="text-nowrap" [routerLink]="['/panel/productos/registro']">Registrar nuevo<i class="cxi-angle-right font-size-base align-middle ml-1"></i></a></p>
    </div>
</div>

<div class="card box-shadow-sm">
    <div class="card-header">
        <h5 style="margin-bottom: 0px;">Filtro de productos</h5>
    </div>
    <div class="card-header">
        <div class="row">
            <div class="col-6">
                <div class="input-group">
                    <input class="form-control" type="text" placeholder="Titulo del producto" name="filtro" [(ngModel)]="filtro">
                    <div class="input-group-append">
                      <button class="btn btn-primary" type="button" (click)="filtrar()">Filtrar</button>
                      <button class="btn btn-info" type="button" (click)="resetar()">Resetear</button>
                    </div>
                </div>
            </div>
            <div class="col-12 mt-3">
              <button class="btn btn-success" (click)="donwload_excel()">Exportar a excel</button>
            </div>
        </div>
    </div>
    <div class="card-body">
        <div class="table-responsive">
            <table class="table table-bordered">
              <thead class="thead-dark">
                <tr>
                 
                  <th>Titulo</th>
                  <th>Stock</th>
                  <th>Precio</th>
                  <th>Categoria</th>
                  <th>Ventas</th>
                  <th>OP</th>
                </tr>
              </thead>
              
              <tbody *ngIf="load_data">
                  <tr>
                    <td class="text-center" colspan="7">
                      <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                    </td>
                  </tr>
              </tbody>

              <ng-container  *ngIf="!load_data">
                  <tbody *ngFor="let item of productos| slice: (page-1)*pageSize : (page-1) * pageSize + pageSize">
                    <tr>
                      <td>
                        <h6 style="padding-bottom: 0;margin-bottom: 0;">
                          <img style="width:50px" [src]="url +'obtener_portada/'+item.portada" class="img-thumbnail rounded-0" alt="Square image">
                          {{item.titulo}}
                        </h6>
                      </td>
                      <td>{{item.stock}}</td>
                      <td>{{item.precio}}</td>
                      <td>{{item.categoria}}</td>
                      <td>{{item.nventas}}</td>
                      <td>
                        <div class="btn-group dropdown d-inline-block mb-3 mr-2">
                          <button class="btn btn-outline-secondary btn-sm dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">OPCIONES</button>
                          <div class="dropdown-menu">
                            <a class="dropdown-item" [routerLink]="['/panel/productos',item._id]"  >Editar producto</a>
                            <a class="dropdown-item" style="cursor: pointer;" data-toggle="modal" [attr.data-target]="'#delete-'+item._id">Eliminar producto</a>
                            <a class="dropdown-item" [routerLink]="['/panel/productos/inventario',item._id]"  >Inventario</a>
                            <a class="dropdown-item" [routerLink]="['/panel/productos/variedades',item._id]"  >Variedades</a>
                            <a class="dropdown-item" [routerLink]="['/panel/productos/galeria',item._id]"  >Galería</a>
                            <a class="dropdown-item" [routerLink]="['/panel/productos/reviews',item._id]"  >Reseñas</a>
                          </div>
                        </div>

                        <div class="modal fade" id="delete-{{item._id}}" tabindex="-1" style="display: none;" aria-hidden="true">
                          <div class="modal-dialog modal-dialog-centered" role="document">
                            <div class="modal-content">
                              <div class="modal-header">
                                <h4 class="modal-title">Confirmación de eliminación</h4>
                                <button class="close" type="button" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
                              </div>
                              <div class="modal-body">
                                <p>{{item.titulo}}, desea eliminar este producto?</p>
                              </div>
                              <div class="modal-footer">
                                <button class="btn btn-secondary btn-sm" type="button" data-dismiss="modal">Cancelar</button>
                                <button *ngIf="!load_btn" class="btn btn-primary btn-shadow btn-sm" (click)="eliminar(item._id)" type="button">Si, eliminar</button>
                                <button *ngIf="load_btn" type="button" class="btn btn-secondary" disabled>
                                  <span class="spinner-grow spinner-grow-sm mr-2" role="status" aria-hidden="true"></span>
                                  Eliminando...
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
              </ng-container>
              
              
            </table>
        </div>
    </div>
    <div class="card-footer">
        <ngb-pagination
          [(page)]="page"
          [pageSize]="pageSize"
          [collectionSize]="productos.length"
        ></ngb-pagination>
    </div>
</div>  