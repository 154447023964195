<app-sidebar></app-sidebar>

<!-- Page title-->
<div class="border-bottom pt-5 mt-2 mb-5">
    <h1 class="mt-2 mt-md-4 mb-3 pt-5">Clientes</h1>
    <div class="d-flex flex-wrap flex-md-nowrap justify-content-between">
      <p class="text-muted">Este módulo lista todos los clientes de la tienda.</p>
      
    </div>
  </div>

    <form #updateForm="ngForm" (ngSubmit)="actualizar(updateForm)">
        <div class="card box-shadow-sm">
                <div class="card-header">
                    <h5 style="margin-bottom: 0px;">Actilizar cliente</h5>
                </div>
                <ng-container *ngIf="load_data">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-12 text-center">
                                <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status">
                                    <span class="sr-only">Loading...</span>
                                  
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="!load_data">
                    <div class="card-body" *ngIf="cliente != undefined">
                    
                        <div class="row">
                            <div class="col-md-4 form-group">
                                <label for="validationCustom01" class="form-label">Nombres</label>
                                <input class="form-control" type="text" placeholder="Nombres completos" required name="nombres" [(ngModel)]="cliente.nombres">
                            </div>
                            <div class="col-md-4 form-group">
                                <label for="validationCustom01" class="form-label">Apellidos</label>
                                <input class="form-control" type="text" placeholder="Apellidos completos" required name="apellidos" [(ngModel)]="cliente.apellidos">
                            </div>
                            <div class="col-md-4 form-group">
                                <label for="validationCustom01" class="form-label">Correo electrónico</label>
                                <input class="form-control" type="email" placeholder="Correo electrónico" required name="email" [(ngModel)]="cliente.email">
                            </div>
    
                            <div class="col-md-4 form-group">
                                <label for="validationCustom01" class="form-label">Telefono</label>
                                <input class="form-control" type="text" placeholder="Telefono" required name="telefono" [(ngModel)]="cliente.telefono">
                            </div>
                            <div class="col-md-4 form-group">
                                <label for="validationCustom01" class="form-label">Fecha nacimiento</label>
                                <input class="form-control" type="date" required name="f_nacimiento" [(ngModel)]="cliente.f_nacimiento">
                            </div>
                            <div class="col-md-4 form-group">
                                <label for="validationCustom01" class="form-label">DNI</label>
                                <input class="form-control" type="number" placeholder="DNI" required name="dni" [(ngModel)]="cliente.dni">
                            </div>
    
                            <div class="col-md-4">
                                <label for="validationCustom01" class="form-label">Genero</label>
                                <select class="form-control custom-select" name="genero" required [(ngModel)]="cliente.genero">
                                    <option value="" disabled selected>Seleccionar</option>
                                    <option value="Masculino">Masculino</option>
                                    <option value="Femenino">Femenino</option>
                                </select>
                            </div>
                        </div>
                        
                    </div>
                    <div class="card-body" *ngIf="cliente == undefined">
                        <h3 class="text-center">No se encontro el registro</h3>
                        <div class="row">
                            <div class="col-3 mx-auto">
                                <img src="assets/img/advertencia.png" alt="" style="width: 100%;">
                            </div>
                        </div>
                    </div>
                </ng-container>
                
                <div class="card-footer">
                    <button *ngIf="!load_btn" class="btn btn-primary mr-3" type="submit">Actualizar cliente</button>
                    <button *ngIf="load_btn" type="button" class="btn btn-secondary" disabled>
                        <span class="spinner-grow spinner-grow-sm mr-2" role="status" aria-hidden="true"></span>
                        Actualizando...
                    </button>
                    <a [routerLink]="['/panel/clientes']" class="btn btn-warning">Regresar</a>
                </div>
        </div>  
    </form>