<app-sidebar></app-sidebar>

<div class="border-bottom pt-5 mt-2 mb-5">
    <h1 class="mt-2 mt-md-4 mb-3 pt-5">Configuraciones</h1>
    <div class="d-flex flex-wrap flex-md-nowrap justify-content-between">
      <p class="text-muted">Este módulo se estableve las configuraciones de la tineda.</p>

    </div>
  </div>

<form #confForm="ngForm" (ngSubmit)="actualizar(confForm)">
  <div class="card box-shadow-sm">
    <div class="card-header">
        <h5 style="margin-bottom: 0px;">Configuraciones</h5>
    </div>

    <div class="card-body">
      <div style="max-width: 48rem;">
        <div class="form-group row align-items-center">
          <label class="col-md-3 col-form-label font-weight-medium" for="text-input">Nombre de la tienda</label>
          <div class="col-md-9">
            <input class="form-control" required type="text" id="text-input" placeholder="Createx" name="titulo" [(ngModel)]="config.titulo">
          </div>
        </div>
        <div class="form-group row align-items-center">
          <label class="col-md-3 col-form-label font-weight-medium" for="text-input">Facturación</label>
          <div class="col-md-4">
            <input class="form-control" required type="number" id="text-input" name="serie" [(ngModel)]="config.serie">
          </div>
          <div class="col-md-5">
            <input class="form-control" required type="number" id="text-input" name="correlativo" [(ngModel)]="config.correlativo">
          </div>
        </div>
        <div class="form-group row align-items-center">
          <label class="col-md-3 col-form-label font-weight-medium" for="text-input">Categorías</label>
          <div class="col-md-4">
            <input class="form-control" type="text" id="text-input" placeholder="Titulo" name="titulo_cat" [(ngModel)]="titulo_cat">
   
          </div>
          <div class="col-md-5">
            <input class="form-control" type="text" id="text-input" placeholder="Icono" name="icono_cat" [(ngModel)]="icono_cat">
          </div>
          <div class="col-md-3">

          </div>
          <div class="col-md-9">
            <a style="cursor: pointer;" (click)="agregar_cat()" class="mb-5">Agregar</a>
            <div class="table-responsive mt-3">
              <table class="table table-sm" *ngIf="config.categorias">
                <thead class="thead-dark">
                  <tr>
             
                    <th>Categoria</th>
                    <th>Icono</th>
                    <th>Eliminar</th>
                  </tr>
                </thead>
                <ng-container *ngIf="config.categorias.length== 0">
                  <tbody>
                    <tr>
                      <td colspan="2" class="text-center">
                        <span class="text-muted">No hay categorias disponibles</span>
                      </td>
                      
                    </tr>
                  </tbody>
                </ng-container>
                <ng-container *ngIf="config.categorias.length>=1">
                  <tbody *ngFor="let item of config.categorias; let indice = index">
                    <tr>
                      <td>
                        {{item.titulo}}
                      </td>
                      <td>
                        <i class="{{item.icono}}"></i> {{item.icono}}
                      </td>
                      <td>
                        <a style="cursor: pointer;" class="text-danger" (click)="eliminar_catergoria(indice)">[Eliminar]</a>
                      </td>
                    </tr>
                  </tbody>
                </ng-container>
                
              </table>
            </div>
          </div>
         
        </div>
        <div class="form-group row align-items-center">
          <label class="col-md-3 col-form-label font-weight-medium" for="file-input">Logo</label>
          <div class="col-md-9">
            <div class="cs-file-drop-area">
              <div class="cs-file-drop-preview img-thumbnail rounded">
                <img [src]="imgSelect" alt="">
              </div>
              <span class="cs-file-drop-message">Arrastre la imagen</span>
              <input type="file" class="cs-file-drop-input" (change)="fileChangeEvent($event)">
              <button type="button" class="cs-file-drop-btn btn btn-outline-primary btn-sm">Subir imagen</button>
            </div>
          </div>
          
        </div>
      </div>
     
    </div>
    <div class="card-footer">
      <button class="btn btn-primary mr-3" type="submit">Guardar cambios</button>
    </div>
</div>  
</form>
