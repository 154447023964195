<app-sidebar></app-sidebar>

<!-- Page title-->
<div class="border-bottom pt-5 mt-2 mb-5">
    <h1 class="mt-2 mt-md-4 mb-3 pt-5">Mensajes</h1>
    <div class="d-flex flex-wrap flex-md-nowrap justify-content-between">
      <p class="text-muted">Este módulo lista todos los mensajes de la tienda.</p>
     
    </div>
</div>

<div class="card box-shadow-sm">
    <div class="card-header">
        <h5 style="margin-bottom: 0px;">Los mensajes</h5>
    </div>

    <div class="card-body" *ngIf="!load_data">
        <div class="mb-4 pb-4 border-bottom" *ngFor="let item of mensajes| slice: (page-1)*pageSize : (page-1) * pageSize + pageSize;">
            <div class="row">
              <div class="col-md-3 col-sm-4 mb-sm-0 mb-3">
                <h3 class="mb-2 font-size-lg">{{item.cliente}}</h3>
                <span class="d-block mb-3 font-size-sm text-muted">{{item.createdAt|date}}</span>
                <span class="badge badge-primary" *ngIf="item.estado=='Abierto'">{{item.estado}}</span>
                <span class="badge badge-danger" *ngIf="item.estado=='Cerrado'">{{item.estado}}</span>
              </div>
              <div class="col">
                <p class="mb-3">
                  <a class="mr-1 font-weight-bold text-decoration-none">{{item.correo}}</a>
                  {{item.mensaje}}
                </p>
                <div class="d-flex justify-content-between">
                  <a class="nav-link p-0 font-size-sm" data-toggle="modal" [attr.data-target]="'#estadoModal-'+item._id">
                    <i class="cxi-chat mr-2 font-size-base align-middle mt-n1"></i>
                    Cerrar mensaje
                  </a>

                  <div class="modal fade" id="estadoModal-{{item._id}}" tabindex="-1" style="display: none;" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered" role="document">
                      <div class="modal-content">
                        <div class="modal-header">
                          <h4 class="modal-title">Cerrar el mensaje</h4>
                          <button class="close" type="button" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
                        </div>
                        <div class="modal-body">
                          <p>Desea cerrar el mensaje?</p>
                        </div>
                        <div class="modal-footer">
                          <button class="btn btn-secondary btn-sm" type="button" data-dismiss="modal">Cancelar</button>
                          <button *ngIf="!load_btn" class="btn btn-primary btn-shadow btn-sm" (click)="cerrar(item._id)" type="button">Si, cerrar</button>
                          <button *ngIf="load_btn" type="button" class="btn btn-secondary">
                            <span class="spinner-grow spinner-grow-sm mr-2" role="status" aria-hidden="true"></span>
                            Cerrando...
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                 
                </div>
              </div>
            </div>
          </div>
    </div>
    <div class="card-body" *ngIf="load_data">
        <div class="row">
            <div class="col-12 text-center">
                <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
            </div>
        </div>
    </div>

    <div class="card-footer">
        <ngb-pagination
          [(page)]="page"
          [pageSize]="pageSize"
          [collectionSize]="mensajes.length"
        ></ngb-pagination>
    </div>
</div>  