<div class="row" style="width: 100%;
        height: 100%;margin-top: 80px;">
        <div class="col-lg-7 mx-auto" style="display: flex;
        align-items: center;">
            <div class="px-md-5 px-4">
                <h3 class="modal-title mt-4 mb-0 text-center mb-5">Iniciar sesión</h3>
                <p class="font-size-sm text-muted text-center">Inicie sesión en su cuenta utilizando el correo electrónico y la contraseña proporcionados durante el registro.</p>
                <form #loginForm="ngForm" (ngSubmit)="login(loginForm)">
                    <div class="form-group">
                        <label for="signin-email">Correo electrónico</label>
                        <input type="email" class="form-control" name="email" [(ngModel)]="user.email" placeholder="Correo electrónico" required="">
                    </div>
                    <div class="form-group">
                        <label for="signin-password" class="form-label">Contraseña</label>
                        <div class="cs-password-toggle input-group-overlay">
                            <input type="password" class="form-control appended-form-control" name="password" [(ngModel)]="user.password" placeholder="Tu contraseña" required="">
                            <div class="input-group-append-overlay">
                                <label class="btn cs-password-toggle-btn input-group-text">
                                    <input type="checkbox" class="custom-control-input">
                                    <i class="cxi-eye cs-password-toggle-indicator"></i>
                                    <span class="sr-only">Show password</span>
                                </label>
                            </div>
                        </div>
                    </div>

                    <button type="submit" class="btn btn-primary btn-block">Ingresar</button>
                    
                </form>
                </div>
        </div>
</div>
